export { default as getOne } from './getOne';
export { default as search } from './search';
export { default as getCrossReferences } from './getCrossReferences';

interface IProductAttribute {
  data: {
    id: number;
    attributes: {
      name: string;
      logo?: string;
    };
  };
}
export interface IProductBasicAttributes {
  code: string;
  description: string;
  active: boolean;
  price_list: number | null;
  price_net: number | null;
  sales_package_sizes: number | null;
  priceDiscounted: number | null;
  promotions: { data: IPromotion[] };
  ean: string | null;
  thumbnail?: string;
}

export interface IProductAttributes extends IProductBasicAttributes {
  family: IProductAttribute;
  manufacturer: IProductAttribute;
  class: IProductAttribute;
  quantity: {
    quantityDefaultWarehouse: number;
    quantityOtherWarehouses: number;
    otherWarehouses: {
      warehouse_name: string;
      warehouse_id: number;
      warehouse_code: string;
      quantity: number;
    }[];
  };
}

interface ITecdocArticleCriteria {
  criteriaAbbrDescription: string;
  criteriaDescription: string;
  criteriaId: number;
  criteriaType: string;
  criteriaUnitDescription: string;
  formattedValue: string;
  immediateDisplay: boolean;
  isInterval: boolean;
  isMandatory: boolean;
  rawValue: string;
}

interface ITecdocGenericArticle {
  genericArticleDescription: string;
  genericArticleId: number;
  legacyArticleId: number;
}

interface ITecdocLink {
  description: string;
  url: string;
}
interface ITecdocImage {
  fileName: string;
  headerDescription: string;
  headerKey: number;
  imageURL50: string;
  imageURL100: string;
  imageURL200: string;
  imageURL400: string;
  imageURL800: string;
  imageURL1600: string;
  imageURL3200: string;
  typeDescription: string;
  typeKey: number;
}
interface ITecdocPDF {
  fileName: string;
  headerDescription: string;
  typeDescription: string;
  url: string;
}

interface ITecdocOEMNumber {
  articleNumber: string;
  matchesSearchQuery: boolean;
  mfrId: number;
  mfrName: string;
}
export interface ITecdocProduct {
  articleCriteria: ITecdocArticleCriteria[];
  articleLogisticsCriteria: any[];
  articleNumber: string;
  articleText: any[];
  comparableNumbers: any[];
  dataSupplierId: number;
  genericArticles: ITecdocGenericArticle[];
  gtins: string[];
  images: ITecdocImage[];
  linkages: any[];
  links: ITecdocLink[];
  mfrId: number;
  mfrName: string;
  misc: {
    articleStatusDescription: string;
    articleStatusId: number;
    articleStatusValidFromDate: number;
    hasMandatoryMaterialCertification: boolean;
    isAccessory: boolean;
    isRemanufacturedPart: boolean;
    isSelfServicePacking: boolean;
    quantityPerPackage: number;
    quantityPerPartPerPackage: number;
  };
  oemNumbers: ITecdocOEMNumber[];
  pdfs: ITecdocPDF[];
  prices: any[];
  replacedByArticles: any[];
  replacesArticles: any[];
  totalLinkages: number;
  tradeNumbers: any[];
}

export interface IProduct {
  id: number;
  attributes: IProductAttributes;
  tecdoc?: ITecdocProduct;
}

export interface IPromotion {
  id: number;
  code: string;
  title: string;
  listTitle: string;
  description: string;
  percentage: number | null;
  note: string | null;
  validFrom: string;
  validTo: string;
  promoConfiguration: IPromoExtraDiscount[] | IPromoTypeOnType[] | IPromoEveryTotProductFree[];
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  image: any;
}

export interface IPromoConfiguration {
  id: number;
  __component: PromoConfigurationType;
}

export interface IPromoTypeOnType extends IPromoConfiguration {
  ogni: number;
  inOmaggio: number;
  perUnMassimoDi?: number;
  terms?: string;
}

export interface IPromoExtraDiscount extends IPromoConfiguration {
  extraScontoFasce: IPromoExtraDiscountItem[];
}

export interface IPromoExtraDiscountItem {
  minimoDi: number;
  extraSconto: number;
}

export interface IPromoEveryTotProductFree extends IPromoConfiguration {
  ogni: number;
  inOmaggio: {
    id: number;
    active: boolean | null;
    code: string;
    createdAt: string;
    description: string | null;
    manufacturer_code: string | null;
    manufacturer_kromeda: string | null;
    manufacturer_tecdoc: string | null;
    price_net: number | null;
    price_list_date: string | null;
    updatedAt: string | null;
  };
  perUnMassimoDi?: number;
}

export enum PromoConfigurationType {
  EXTRA_DISCOUNT = 'promo.extra-sconto',
  TYPE_ON_TYPE = 'promo.tipo-su-tipo',
  EVERY_X_FOR_FREE = 'promo.ogni-tot-in-omaggio',
}
