import { useFormContext } from 'react-hook-form';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import { ISofinnUpdateProfileForm } from '../../../lib/interfaces/ISofinn';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';

interface Props {
  onUpdateSofinnProfile: (values: ISofinnUpdateProfileForm) => void;
  onRedirect: () => void;
  isError: boolean;
}

const SofinnUpdateProfileForm: React.FC<Props> = (props) => {
  const { onUpdateSofinnProfile, onRedirect, isError } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<ISofinnUpdateProfileForm, Omit<ISofinnUpdateProfileForm, 'confirmPassword'>>();

  return (
    <form
      onSubmit={handleSubmit(onUpdateSofinnProfile)}
      className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]"
    >
      <h4 className="uppercase">Modifica password</h4>
      <div className="grid gap-2 grid-cols-1 mt-4">
        <span className="text-[14px]">E-Mail:</span>
        <SofinnInput
          placeholder="Indirizzo email"
          {...register('email')}
          hasError={!!errors.email}
          helperText={errors.email?.message}
        />
        <span className="text-[14px]">Password:</span>
        <SofinnInput
          placeholder="Password"
          type="password"
          {...register('password')}
          hasError={!!errors.password}
          helperText={errors.password?.message}
        />
        <span className="text-[14px]">Ripeti password:</span>
        <SofinnInput
          placeholder="Ripeti password"
          type="password"
          {...register('confirmPassword')}
          hasError={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
        />
      </div>
      {isError && (
        <div className="mt-2">
          <SofinnErrorBox
            errorMessage={
              "Ooops... Si è verificato un errore in fase di aggiornamento profilo. Riprovare più tardi oppure contattare l'assistenza"
            }
            className="pt-2"
          />
        </div>
      )}

      <div className="flex flex-row justify-between">
        <SofinnButton
          text="Annulla"
          type="button"
          onClick={() => onRedirect()}
          className="max-w-min px-[20px] text-gray-800 hover:bg-[#dc2626]/75 bg-[#dc2626]"
        />
        <SofinnButton text="Invia" className="max-w-min px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75" />
      </div>
    </form>
  );
};

export default SofinnUpdateProfileForm;
