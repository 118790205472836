import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Tab } from '@headlessui/react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/Breadcrumbs';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { IProduct } from '../../lib/fetch/products';
// import PromotionPrice from './Components/PromotionPrice';
// import { DeliveryBox } from './Components';
import { ITab, TabBarBig } from '../../components/TabBars';
import FullScreenLoading from '../../components/FullScreenLoading';
import {
  MadeOrderBadge,
  OutProductionBadge,
  PiecesBadge,
  TransitingBadge,
  UnavailableBadge,
  WarehouseModal,
} from '../../components/StockBadge';
import { classNames, currency, quantityInputProps as _quantityInputProps } from '../../lib/utils';
import { DEFAULT_IMAGE_PLACEHOLDER, HIDDEN_MANUFACTURERS_NAMES } from '../../lib/constants';
import ShoppingCartLottie from '../../lotties/shopping-cart.json';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from '../../lib/utils/manufacturers';
import { useSettings } from '../../providers/SettingsProvider';
import { CrossReferences, TechnicalSpecs } from './Tabs';
import { ProductDetailsTab } from '../../lib/enums';
import { PromotionPrice } from './Components';
import { getPromoDescription } from '../../lib/utils/promotions';
const { REACT_APP_MEDIA_LIBRARY_URL } = process.env;

interface IProductDetailPresentational {
  adding: boolean;
  onAddToCart: (product: IProduct, quantity: number) => void;
  loading: boolean;
  product?: IProduct;
  crossReferences: IProduct[];
  loadingCrossReferences: boolean;
}

const IMAGE_PLACEHOLDER = Object.freeze({
  id: 0,
  lang: '',
  description: 'Picture not available',
  name: 'Picture not available',
  type: 'JPG',
  url: DEFAULT_IMAGE_PLACEHOLDER,
});

const ProductDetailPresentational: FC<IProductDetailPresentational> = (props) => {
  const {
    crossReferences = [],
    loadingCrossReferences = false,
    adding = false,
    onAddToCart = () => {},
    loading = false,
    product,
  } = props;
  const [{ tecdocManufacturers }] = useSettings();
  const {
    t,
    i18n: { language },
  } = useTranslation('PRODUCTS');
  const { handleSubmit, register, setValue: setFormValue } = useForm();
  const [selectedTab, setSelectedTab] = useState(ProductDetailsTab.TECHNICAL_SPECS);

  const detailsTabs: ITab[] = [
    {
      name: t(ProductDetailsTab.TECHNICAL_SPECS),
      current: selectedTab === ProductDetailsTab.TECHNICAL_SPECS,
      onClick: () => setSelectedTab(ProductDetailsTab.TECHNICAL_SPECS),
    },
    {
      name: t(ProductDetailsTab.CROSS_REFERENCES),
      current: selectedTab === ProductDetailsTab.CROSS_REFERENCES,
      onClick: () => setSelectedTab(ProductDetailsTab.CROSS_REFERENCES),
    },
  ];

  if (loading)
    return <FullScreenLoading loading={loading} title={t('LOADING_TITLE')} subtitle={t('LOADING_SUBTITLE')} />;
  if (!product) return null;

  const { attributes, tecdoc } = product;

  const { manufacturer, description: name, price_net: price, quantity } = attributes;

  let manufacturerName = manufacturer?.data.attributes.name;
  if (HIDDEN_MANUFACTURERS_NAMES.includes((manufacturerName || '').toUpperCase())) {
    manufacturerName = `${manufacturer?.data.id || ''}`;
  }
  const manufacturerLogo = (
    tecdoc?.dataSupplierId
      ? getTecdocManufacturerById(tecdoc?.dataSupplierId, tecdocManufacturers)
      : getTecdocManufacturerByName(manufacturer?.data.attributes.name, tecdocManufacturers)
  )?.dataSupplierLogo.imageURL800;

  const images =
    tecdoc?.images.map((image, idx) => ({
      id: idx,
      name: image.fileName,
      url: image.imageURL3200,
    })) || [];

  if (product.attributes.thumbnail) {
    images.push({
      id: 0,
      name: 'Product thumbnail',
      url: product.attributes.thumbnail,
    });
  }

  if (!images.length) images.push(IMAGE_PLACEHOLDER);

  const quantityInputProps = _quantityInputProps(product);
  setFormValue('quantity', quantityInputProps.defaultValue);
  const disabled =
    quantityInputProps.disabled ||
    !price ||
    /*     (product.attributes.quantity.quantityDefaultWarehouse === 0 &&
      product.attributes.quantity.quantityOtherWarehouses === 0) || */
    !product.attributes.active;

  return (
    <div className="min-h-screen">
      <Header />

      <Breadcrumbs product={product} />

      {adding && (
        <FullScreenLoading
          loading={adding}
          lottie={ShoppingCartLottie}
          title={t('SHOPPING_CART:UPDATING_TITLE')}
          subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
          height={200}
          width={400}
        />
      )}

      <div className="mx-auto w-full flex-grow overflow-x-hidden lg:flex lg:px-4">
        <div className="mb-10 flex flex-1 flex-col items-stretch overflow-hidden xl:flex-row">
          <main className="flex-1 overflow-y-auto">
            <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last">
              <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                  {/* Image gallery */}
                  <Tab.Group as="div" className="flex flex-col-reverse lg:flex-row">
                    {/* Image selector */}
                    <div className="px-2 py-4 sm:block lg:max-w-none lg:pr-4 lg:pt-2 lg:pb-2">
                      <Tab.List className="flex flex-row gap-4 lg:flex-col">
                        {images.map((image) => (
                          <Tab
                            key={image.id}
                            className="relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none xl:h-28 xl:w-28 "
                          >
                            {({ selected }) => (
                              <>
                                <span className="sr-only">{image.name}</span>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img
                                    src={image.url}
                                    alt={image.name}
                                    className="h-full w-full object-cover object-center"
                                  />
                                </span>
                                <span
                                  className={classNames(
                                    selected ? 'ring-red-500' : 'ring-transparent',
                                    'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                      {images.map((image) => (
                        <Tab.Panel key={image.id}>
                          <img
                            src={image.url}
                            alt={image.name}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>

                  {/* Product info */}
                  <div className="mt-6 sm:px-0 lg:mt-0">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.attributes.code}</h1>
                    <h2 className="text-2xl font-bold tracking-tight text-gray-600">
                      {product.attributes.description ||
                        product.tecdoc?.genericArticles[product.tecdoc?.genericArticles.length - 1]
                          .genericArticleDescription ||
                        name ||
                        product.attributes.family?.data.attributes.name}
                    </h2>

                    <div className="mt-3">
                      {product.attributes.priceDiscounted ? (
                        <PromotionPrice product={product} />
                      ) : (
                        <>
                          <p className={classNames(price ? 'text-3xl text-gray-700' : 'text-2xl text-gray-500')}>
                            {price ? currency(price) : t('PRICE_NOT_AVAILABLE')}
                          </p>
                          {!!product.attributes.promotions.data.length &&
                            product.attributes.promotions.data.map((promotion) => (
                              <div key={promotion.id} className="mt-4 rounded-md bg-gray-100 px-3 py-2">
                                <p className="text-xs text-gray-700">{t('PROMOTION_AVAILABLE')}:</p>
                                <p
                                  className=" text-sm text-red-500"
                                  dangerouslySetInnerHTML={{
                                    __html: getPromoDescription(promotion, language),
                                  }}
                                ></p>
                                <p className="mt-3 text-xs text-gray-700">{t('MENU:DESCRIPTION')}: </p>
                                <p className="text-xs text-gray-500">{promotion.description}</p>
                                {promotion?.image?.url && (
                                  <img
                                    className="mt-3 mb-3 h-auto"
                                    src={
                                      promotion.image.url.startsWith('https://')
                                        ? promotion.image.url
                                        : `${REACT_APP_MEDIA_LIBRARY_URL}${promotion.image.url}`
                                    }
                                    alt="promozione"
                                  />
                                )}
                              </div>
                            ))}
                        </>
                      )}
                    </div>

                    <div className="mt-10">
                      {manufacturerLogo ? (
                        <img className="mb-3 h-auto w-24" src={manufacturerLogo} alt={manufacturerName} />
                      ) : (
                        manufacturerName
                      )}
                      <p className="text-sm text-gray-500 ">{/* {standardDescription || ''} */}</p>
                    </div>

                    <div className="mt-10">
                      {!product.attributes.active && <OutProductionBadge />}
                      {product.attributes.active &&
                        product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                        product.attributes.quantity.quantityOtherWarehouses === 0 && <UnavailableBadge />}
                      {product.attributes.active && product.attributes.quantity.quantityDefaultWarehouse > 0 && (
                        <div>
                          <PiecesBadge quantity={product.attributes.quantity.quantityDefaultWarehouse} />
                        </div>
                      )}
                      {product.attributes.active &&
                        product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                        product.attributes.quantity.quantityOtherWarehouses > 0 && (
                          <>
                            <MadeOrderBadge quantity={product.attributes.quantity.quantityOtherWarehouses} />
                            <WarehouseModal otherWarehouses={product.attributes.quantity.otherWarehouses} />
                          </>
                        )}
                    </div>
                    <p className="text-xs">
                      {t('MIN_QUANTITY')}
                      {product?.attributes?.sales_package_sizes
                        ? Math.floor(product?.attributes?.sales_package_sizes)
                        : 1}
                    </p>

                    <form
                      className="mt-10 flex items-center"
                      onSubmit={handleSubmit(({ quantity }) => !disabled && onAddToCart(product, +quantity || 0))}
                    >
                      <input
                        type="number"
                        className="mr-2 block w-20 rounded-md border-transparent bg-gray-100 py-2 text-sm shadow-sm focus:border-red-500 focus:ring-red-500"
                        {...quantityInputProps}
                        min={1}
                        // max={quantity.quantityDefaultWarehouse + quantity.quantityOtherWarehouses}
                        {...register('quantity', {
                          required: true,
                          // min: quantityInputProps.min,
                          // max: quantity.quantityDefaultWarehouse + quantity.quantityOtherWarehouses,
                          disabled,
                          valueAsNumber: true,
                        })}
                      />
                      <button
                        type="submit"
                        disabled={disabled}
                        className={classNames(
                          'flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
                          !disabled && 'hover:bg-red-700',
                        )}
                        style={disabled ? { cursor: 'not-allowed' } : {}}
                      >
                        <ShoppingCartIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        {t('ADD_TO_CART')}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <aside className="mx-auto w-full max-w-2xl overflow-y-auto bg-white py-6 px-4 lg:px-0 xl:w-96 xl:max-w-none xl:pr-0 xl:pl-4">
            {/* TODO: DeliveryBox */}
            {/*<DeliveryBox />*/}
          </aside>
        </div>
      </div>

      <div className="mb-10 px-4">
        <div className="mb-4 sm:mx-4 sm:mb-0">
          <TabBarBig tabs={detailsTabs} />
        </div>
        <div className="rounded-md border border-gray-200 p-6 px-4">
          {selectedTab === ProductDetailsTab.TECHNICAL_SPECS && (
            <>
              <div className="px-3 py-4">
                <TechnicalSpecs product={product} />
              </div>
            </>
          )}
          {selectedTab === ProductDetailsTab.CROSS_REFERENCES && (
            <>
              <div className="py-4 px-3">
                <CrossReferences
                  crossReferences={crossReferences}
                  onAddToCart={onAddToCart}
                  loading={loadingCrossReferences}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailPresentational;
