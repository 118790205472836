import { useEffect } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { redirectToSofinn } from '../../../components/Sofinn/utils';
import { useNavigate } from 'react-router-dom';
import RedirectPresentational from '../../Redirect/RedirectPresentational';
import { IUser } from '../../../lib/interfaces';

const RedirectSofinn: React.FC = () => {
  const navigate = useNavigate();
  const [{ sofinnUser, sofinnToken }, dispatchAuthChange] = useAuth();

  const handleRedirect = ({ _sofinnToken, _sofinnUser }: { _sofinnToken: string | null; _sofinnUser: IUser }) => {
    if (_sofinnUser.isSingleUsePassword) {
      return navigate('/cati-b2b/reset-temporary-password');
    }

    redirectToSofinn({ sofinnToken: _sofinnToken, dispatchAuthChange });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!sofinnToken || !sofinnUser) {
        navigate('/cati-b2b/login');
      }
    }, 10000);

    if (sofinnUser) {
      clearInterval(timer);
      if (sofinnUser?.hasCompletedSofinnPwChangeProcedure) {
        return handleRedirect({ _sofinnToken: sofinnToken, _sofinnUser: sofinnUser });
      }

      return navigate('/cati-b2b/question-reset-password');
    }
  }, [sofinnUser]);

  return <RedirectPresentational message={'Abbiamo preso in carico la tua richiesta. per piacere attendere...'} />;
};

export default RedirectSofinn;
