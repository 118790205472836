import { useNavigate } from 'react-router-dom';
import FooterSofinn from '../../../components/Sofinn/FooterSofinn';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import { useAuth } from '../../../providers/AuthProvider';
import { redirectToSofinn } from '../../../components/Sofinn/utils';

const SofinnQuestionResetPassword: React.FC = () => {
  const [{ sofinnToken }, dispatchAuthChange] = useAuth();

  const navigate = useNavigate();

  const onAcceptResetPassword = () => {
    navigate('/cati-b2b/reset-password');
  };

  const handleRedirect = () => {
    redirectToSofinn({ sofinnToken, dispatchAuthChange });
  };

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>
        <div className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]">
          <h4 className="uppercase">Vuoi modificare la password?</h4>
          <div className="flex flex-row justify-between">
            <SofinnButton
              text="No"
              onClick={() => handleRedirect()}
              className="max-w-min px-[20px] text-gray-800 hover:bg-[#dc2626]/75 bg-[#dc2626]"
            />
            <SofinnButton
              onClick={onAcceptResetPassword}
              text="Sì"
              className="max-w-min px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75"
            />
          </div>
        </div>
      </div>

      <FooterSofinn />
    </div>
  );
};

export default SofinnQuestionResetPassword;
