import * as yup from 'yup';
import { EMAIL_REGEXP, PASSWORD_REGEXP } from '../../../lib/constants/regexp';

const sofinnUpdateUserFormValidator: any = yup
  .object({
    email: yup.string().required('Questo campo è obbligatorio').matches(EMAIL_REGEXP, 'Email non valida'),
    password: yup
      .string()
      .required('Questo campo è obbligatorio')
      .min(8, 'La password deve contenere almeno 8 caratteri')
      .matches(
        PASSWORD_REGEXP,
        'Password non valida. La password deve contenere almeno una maiuscola, una minuscola e un numero',
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Le password devono coincidere')
      .required('Questo campo è obbligatorio'),
  })
  .required();

export default sofinnUpdateUserFormValidator;
