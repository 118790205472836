import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PrivateRoute, PublicRoute } from './components/Auth';
import ActivationRequest from './pages/ActivationRequest';
import EditProfile from './pages/EditProfile';
import SearchResults from './pages/SearchResults';
import ProductDetail from './pages/ProductDetail';
import ShoppingCart from './pages/ShoppingCart';
import CheckoutShipping from './pages/CheckoutShipping';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutResult from './pages/CheckoutResult';
import MyOrders from './pages/MyOrders';
import Faq from './pages/Faq';
import Forgot from './pages/Forgot';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import NewsList from './pages/NewsList';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import Reset from './pages/Reset';
import Shipping from './pages/Shipping';
import TermsConditions from './pages/TermsConditions';
import ThankYouRegistration from './pages/ThankYouRegistration';
import './App.css';
import Agent from './pages/Agent';
import Rva from './pages/Rva';
import AgentRoute from './components/Auth/AgentRoute';
import RvaRoute from './components/Auth/RvaRoute';
import AgentCart from './pages/AgentCart';
import SofinnLogin from './pages/Sofinn/SofinnLogin';
import SofinnQuestionResetPassword from './pages/Sofinn/SofinnQuestionResetPassword';
import SofinnUpdateProfile from './pages/Sofinn/SofinnUpdateProfile';
import SofinnPrivateRoute from './components/Auth/SofinnPrivateRoute';
import SofinnForgotPassword from './pages/Sofinn/SofinnForgotPassword';
import SofinnForgotPasswordFeedback from './pages/Sofinn/SofinnForgotPasswordFeedback';
import SofinnResetPassword from './pages/Sofinn/SofinnResetPassword';
import SofinnResetPasswordFeedback from './pages/Sofinn/SofinnResetPasswordFeedback';
import ResetTemporaryPassword from './pages/Profile/components/ResetTemporaryPassword';
import Redirect from './pages/Redirect';
import RedirectSofinn from './pages/Sofinn/RedirectSofinn';
import SofinnResetTemporaryPassword from './pages/Sofinn/SofinnResetTemporaryPassword';
import SofinnResetTemporaryPasswordFeedback from './pages/Sofinn/SofinnResetTemporaryPasswordFeedback';
import { hasRequiredOptions } from './lib/utils';

export const SEARCH_RESULTS_PATHNAME = '/search-results';
export const PRODUCT_DETAIL_PATHNAME = '/product-detail';
export const CHECKOUT_PAYMENT_PATHNAME = '/checkout-payment';
export const MY_ORDERS_PATHNAME = '/my-orders';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 0 },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {/* Public routes, with redirect if user is authenticated */}
        <Route index element={<PublicRoute element={<Login />} />} />
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        {/* SOFINN ROUTES */}
        <Route path="/cati-b2b/login" element={<PublicRoute element={<SofinnLogin />} />} />
        <Route
          path="/cati-b2b/question-reset-password"
          element={<SofinnPrivateRoute element={<SofinnQuestionResetPassword />} />}
        />
        <Route path="/cati-b2b/reset-password" element={<SofinnPrivateRoute element={<SofinnUpdateProfile />} />} />
        <Route path="/cati-b2b/forgot-password" element={<PublicRoute element={<SofinnForgotPassword />} />} />
        <Route
          path="/cati-b2b/forgot-password-feedback"
          element={<PublicRoute element={<SofinnForgotPasswordFeedback />} />}
        />
        <Route path="/cati-b2b/reset" element={<PublicRoute element={<SofinnResetPassword />} />} />
        <Route
          path="/cati-b2b/reset-temporary-password"
          element={<SofinnPrivateRoute element={<SofinnResetTemporaryPassword />} />}
        />
        <Route
          path="/cati-b2b/reset-temporary-password-feedback"
          element={<SofinnPrivateRoute element={<SofinnResetTemporaryPasswordFeedback />} />}
        />
        <Route path="/cati-b2b/reset-feedback" element={<PublicRoute element={<SofinnResetPasswordFeedback />} />} />
        <Route path={'/cati-b2b/redirect'} element={<PublicRoute element={<RedirectSofinn />} />} />s
        {/* ******************************************************* */}
        <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
        <Route path="/reset" element={<PublicRoute element={<Reset />} />} />
        <Route path="/activation-request" element={<PublicRoute element={<ActivationRequest />} />} />
        <Route path="/thank-you-registration" element={<PublicRoute element={<ThankYouRegistration />} />} />
        {/* Private routes, with redirect if user is NOT authenticated */}
        <Route path="/profile/edit" element={<PrivateRoute element={<EditProfile />} />} />
        <Route path="/profile/*" element={<PrivateRoute element={<Profile />} />} />
        <Route
          path="/profile/reset-temporary-password"
          element={<PrivateRoute element={<ResetTemporaryPassword />} />}
        />
        <Route path={SEARCH_RESULTS_PATHNAME} element={<PrivateRoute element={<SearchResults />} />} />
        <Route path={`${PRODUCT_DETAIL_PATHNAME}/:id`} element={<PrivateRoute element={<ProductDetail />} />} />
        <Route path="/shopping-cart" element={<PrivateRoute element={<ShoppingCart />} />} />
        <Route
          path="/checkout-shipping"
          element={
            <PrivateRoute
              element={<CheckoutShipping />}
              hasUserRequiredOptions={hasRequiredOptions.useUserHasRequiredOptions({ ordersEnabled: true })}
              hasCustomerRequiredOptions={hasRequiredOptions.useCustomerHasRequiredOptions(
                { status: 3 },
                (a, b) => a < b,
              )}
            />
          }
        />
        <Route
          path={MY_ORDERS_PATHNAME}
          element={
            <PrivateRoute
              element={<MyOrders />}
              hasUserRequiredOptions={hasRequiredOptions.useUserHasRequiredOptions({ ordersReview: true })}
            />
          }
        />
        <Route
          path={CHECKOUT_PAYMENT_PATHNAME}
          element={
            <PrivateRoute
              element={<CheckoutPayment />}
              hasUserRequiredOptions={hasRequiredOptions.useUserHasRequiredOptions({ ordersEnabled: true })}
              hasCustomerRequiredOptions={hasRequiredOptions.useCustomerHasRequiredOptions(
                { status: 3 },
                (a, b) => a < b,
              )}
            />
          }
        />
        <Route path={'/checkout-result'} element={<PrivateRoute element={<CheckoutResult />} />} />
        <Route path={'/news'} element={<PrivateRoute element={<NewsList />} />} />
        {/* Agent routes */}
        <Route path={'/agent'} element={<AgentRoute element={<Agent />} />} />
        {/* RVA routes */}
        <Route path={'/rva'} element={<RvaRoute element={<Rva />} />} />
        <Route path={'/agent-cart'} element={<RvaRoute element={<AgentCart />} />} />
        {/* Public routes, without redirect */}
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/*" element={<NotFound />} />
        <Route path={'/redirect'} element={<PrivateRoute element={<Redirect />} />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
