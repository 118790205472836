import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import { ICartItem } from '../../lib/fetch/cartItems';
import { SORTING_OPTIONS } from '../../components/ProductsTable/HorizontalFilter';
import ShoppingCartPresentational from './ShoppingCartPresentational';
import {
  cartUtils,
  IAddToCartWithFeedback,
  IDeleteCartItemWithFeedback,
  IUpdateCartWithFeedback,
} from '../../lib/utils';
import { search as callSearchProducts } from '../../lib/fetch/products';
import { WhereToSearch } from '../../lib/enums';
import i18n from '../../i18n';

const ShoppingCart = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  let { addresses } = user!;
  addresses = addresses || [];
  const { cart } = user!;
  const { shipping_address } = cart || {};
  let shippingAddressId = shipping_address && shipping_address.id;
  const [, dispatchAlertChange] = useAlert();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('SHOPPING_CART');
  const isShippingAddressIdValid = addresses.some(({ id }) => id === shippingAddressId);
  const deliveryAddressId = isShippingAddressIdValid ? shippingAddressId : undefined;
  const lang = i18n.languages[0];

  const onAddToCart = async (search: string) => {
    if (!search) return;
    setLoading(true);
    const { data: axiosData, error } = await callSearchProducts(token!, {
      page: 1,
      pageSize: 2, // We just want to know if there is more than ONE product.
      search,
      sort: SORTING_OPTIONS[0].value,
      where: WhereToSearch.Code,
      lang,
    });
    if (error || !axiosData) {
      setLoading(false);
      return dispatchAlertChange({ open: true });
    }
    const { data = [] } = axiosData;
    if (!data.length) {
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:NO_RESULT_FOR_CODE'),
      });
    }
    if (data.length > 1) {
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:ADD_TO_CART_MULTIPLE_ERROR'),
      });
    }
    dispatchCartOverlayChange({ open: false });
    const [product] = data;

    const quantity = product?.attributes.sales_package_sizes ? Math.floor(product?.attributes.sales_package_sizes) : 1;

    await cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: [{ product, quantity }],
      setAdding: setLoading,
      t,
      token,
      user,
      lang,
      onClose:
        quantity > 1
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + quantity + ')',
              });
            }
          : () => {},
    } as IAddToCartWithFeedback);
  };

  const onItemQuantityChange = async (cartItem: ICartItem, newQuantity: number) => {
    const product = cartItem.product;
    let quantityForced = newQuantity;
    const minQuantity = product?.sales_package_sizes || 1;
    if (
      product?.sales_package_sizes &&
      Math.floor(product?.sales_package_sizes) !== 1 &&
      newQuantity % product.sales_package_sizes !== 0
    ) {
      if (newQuantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (newQuantity % minQuantity !== 0) {
        quantityForced = Math.ceil(newQuantity / minQuantity) * minQuantity;
      }
    }

    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: quantityForced,
      setLoading,
      t,
      token,
      user,
      lang,
      onClose:
        quantityForced > newQuantity
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + minQuantity + ')',
              });
            }
          : () => {},
    } as IUpdateCartWithFeedback);
  };

  const onItemRemove = async (cartItem: ICartItem) => {
    await cartUtils.deleteCartItemWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      setLoading,
      t,
      token,
      user,
      lang,
    } as IDeleteCartItemWithFeedback);
  };

  const onExcerptChange = async (cartItem: ICartItem, excerpt: boolean) => {
    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: cartItem.quantity,
      excerpt: excerpt,
      setLoading,
      t,
      token,
      user,
      lang,
    } as IUpdateCartWithFeedback);
  };

  return (
    <ShoppingCartPresentational
      cart={cart}
      deliveryAddressId={deliveryAddressId || undefined}
      loading={loading}
      readOnly={false}
      onAddToCart={onAddToCart}
      onItemQuantityChange={onItemQuantityChange}
      onItemRemove={onItemRemove}
      onExcerptChange={onExcerptChange}
    />
  );
};

export default ShoppingCart;
