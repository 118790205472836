import { FormProvider, useForm } from 'react-hook-form';
import SofinnResetTemporaryPasswordPresentational from './SofinnResetPasswordPresentational';
import { useMutation, useQuery } from 'react-query';
import { ISofinnResetPasswordForm } from '../../../lib/interfaces/ISofinn';
import { yupResolver } from '@hookform/resolvers/yup';
import resetTemporaryPasswordValidator from './validator';
import { useNavigate } from 'react-router-dom';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import resetTemporaryPassword from '../../../lib/fetch/auth/resetTemporaryPassword';
import IResetTemporaryPasswordForm from '../../Profile/components/ResetTemporaryPassword/interfaces/resetPasswordInAppForm';
import { me } from '../../../lib/fetch/auth';

const SofinnResetTemporaryPassword: React.FC = () => {
  const navigate = useNavigate();
  const [{ sofinnToken }, dispatchAuthChange] = useAuth();

  const formMethods = useForm<ISofinnResetPasswordForm, ISofinnResetPasswordForm>({
    //@ts-ignore
    resolver: yupResolver(resetTemporaryPasswordValidator),
    mode: 'onSubmit',
  });

  const handleRedirect = ({ errorMessage, successMessage }: { errorMessage?: string; successMessage?: string }) => {
    const formattedErrorMessage = errorMessage ? `errorMessage=${errorMessage}` : null;
    const formattedSuccessMessage = successMessage ? `successMessage=${successMessage}` : null;

    navigate(`/cati-b2b/reset-temporary-password-feedback?${formattedSuccessMessage || formattedErrorMessage || ''}`);
  };

  const { refetch: refetchMe } = useQuery('meSofinnQuery', () => me(String(sofinnToken)), {
    enabled: false,
    onSuccess: (data) => {
      dispatchAuthChange({
        type: AuthActionType.SetSofinnUser,
        sofinnUser: data.data,
      });

      handleRedirect({
        successMessage: 'Password aggiornata con successo. A breve sarai reindirizzato sul portale...',
      });
    },
    onError: () => {
      handleRedirect({
        errorMessage:
          "C'è stato un errore in fase di reimpostazione password. Riprovare a ripetere la procedura oppure contattare l'assistenza.",
      });
    },
  });

  const { mutate } = useMutation('sofinnResetPassword', resetTemporaryPassword, {
    onSuccess: () => {
      refetchMe();
    },
    onError: () => {
      handleRedirect({
        errorMessage:
          "C'è stato un errore in fase di reimpostazione password. Riprovare a ripetere la procedura oppure contattare l'assistenza.",
      });
    },
  });

  const onResetPassword = (values: IResetTemporaryPasswordForm) => {
    mutate({ token: sofinnToken, resetData: values });
  };

  return (
    <FormProvider {...formMethods}>
      <SofinnResetTemporaryPasswordPresentational onResetPassword={onResetPassword} />
    </FormProvider>
  );
};

export default SofinnResetTemporaryPassword;
