import { useFormContext } from 'react-hook-form';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import { ISofinnForgotPasswordForm } from '../../../lib/interfaces/ISofinn';

interface Props {
  onForgotPassword: (values: ISofinnForgotPasswordForm) => void;
}

const SofinnForgotPasswordForm: React.FC<Props> = (props) => {
  const { onForgotPassword } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<ISofinnForgotPasswordForm, Omit<ISofinnForgotPasswordForm, 'confirmPassword'>>();

  return (
    <form
      onSubmit={handleSubmit(onForgotPassword)}
      className="flex md:mx-auto flex-col border p-[15px] rounded-[3px] md:w-[400px] w-full my-[50px]"
    >
      <h4 className="uppercase">Hai dimenticato la password?</h4>
      <div className="grid gap-2 grid-cols-1 mt-4">
        <span className="text-[14px]">Inserisci il tuo codice utente:</span>
        <SofinnInput
          placeholder="Codice utente"
          {...register('identifier')}
          hasError={!!errors.identifier}
          helperText={errors.identifier?.message}
        />
      </div>
      <div className="flex flex-row justify-between">
        <div></div>
        <SofinnButton text="Invia" className="max-w-min px-[20px] bg-[#dc2626] hover:bg-[#dc2626]/75" />
      </div>
    </form>
  );
};

export default SofinnForgotPasswordForm;
