import { AuthAction, AuthActionType } from '../../../providers/AuthProvider';

const { REACT_APP_REDIRECT_TO_SOFINN_URL } = process.env;

interface Props {
  sofinnToken: string | null;
  dispatchAuthChange: (_action: AuthAction) => void;
}

const redirectToSofinn = (props: Props) => {
  const { sofinnToken, dispatchAuthChange } = props;
  const url = `${REACT_APP_REDIRECT_TO_SOFINN_URL}?token=${sofinnToken}`;
  dispatchAuthChange({ type: AuthActionType.LogoutSofinn });
  window.location.replace(url);
};

export default redirectToSofinn;
