import { FormProvider, useForm } from 'react-hook-form';
import SofinnResetPasswordPresentational from './SofinnResetPasswordPresentational';
import { useMutation } from 'react-query';
import { ISofinnResetPasswordForm } from '../../../lib/interfaces/ISofinn';
import { yupResolver } from '@hookform/resolvers/yup';
import sofinnResetPasswordFormValidator from './validator';
import { reset } from '../../../lib/fetch/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const SofinnResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const formMethods = useForm<ISofinnResetPasswordForm, ISofinnResetPasswordForm>({
    //@ts-ignore
    resolver: yupResolver(sofinnResetPasswordFormValidator),
    mode: 'onSubmit',
  });

  const handleRedirect = (errorMessage?: string) => {
    const error = errorMessage ? `?errorMessage=${errorMessage}` : '';
    navigate(`/cati-b2b/reset-feedback${error}`);
  };

  const { mutate } = useMutation('sofinnResetPassword', reset, {
    onSuccess: () => {
      handleRedirect();
    },
    onError: () => {
      handleRedirect(
        "C'è stato un errore in fase di reimpostazione password. Riprovare a ripetere la procedura oppure contattare l'assistenza.",
      );
    },
  });

  const onResetPassword = (values: ISofinnResetPasswordForm) => {
    mutate(values);
  };

  useEffect(() => {
    const code = String(searchParams.get('code'));
    formMethods.setValue('code', code);
    return () => {};
  }, [searchParams]);

  return (
    <FormProvider {...formMethods}>
      <SofinnResetPasswordPresentational onRedirect={handleRedirect} onResetPassword={onResetPassword} />
    </FormProvider>
  );
};

export default SofinnResetPassword;
