import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct } from '../../lib/fetch/products';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../lib/constants';
import { ImageMediaType } from '../../lib/enums';
import { SingleProduct } from './SingleProduct';

export { default as HorizontalFilter } from './HorizontalFilter';
export { default as Pagination } from './Pagination';
export { default as WarehouseTable } from './WarehouseTable';

interface IProductsTable {
  onAddToCart: (product: IProduct, quantity: number) => void;
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products: IProduct[];
}

export const isBackgroundCompatible = (thumbnail: string) => {
  const url = (thumbnail || '').split('?').shift();
  const ext = (url || '').split('.').pop();
  return ([ImageMediaType.JPG, ImageMediaType.JPEG, ImageMediaType.PNG] as string[]).includes(
    (ext || '').toUpperCase(),
  );
};
export const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '3rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '3rem',
        }}
      />
    );
  return <img className="h-12 w-12" style={{ minWidth: '3rem' }} src={encodeURI(thumbnail)} alt="thumbnail" />;
};

const ProductsTable: FC<IProductsTable> = (props) => {
  const { onAddToCart = () => {}, onFetchCrossReferences, products = [] } = props;
  const { t } = useTranslation('PRODUCTS');

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only" />
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE_NET')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('FINAL_PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {products.map((product) => (
                  <SingleProduct
                    key={product.id}
                    product={product}
                    onAddToCart={onAddToCart}
                    onFetchCrossReferences={onFetchCrossReferences}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
