import React from 'react';
import { Promotion } from '../../../lib/models/Promotion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import 'swiper/css/mousewheel';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/grid';

// Import Swiper styles
import 'swiper/css';

import './carousel.css';

interface IPromomotionsCarousel {
  promotions: Promotion[];
}

const PromotionCarousel: React.FC<IPromomotionsCarousel> = (props: IPromomotionsCarousel) => {
  const { promotions } = props;

  return (
    <section className="w-full">
      <div className="lg:mx-auto max-w-5xl mx-[1.5rem]">
        <Swiper
          watchOverflow={false}
          modules={[Pagination, Navigation, Autoplay]}
          centeredSlides={true}
          pagination={{
            enabled: true,
            clickableClass: 'swiper-pagination-clickable carousel-bullets-wrapper',
            dynamicBullets: true,
            clickable: true,
          }}
          autoplay={{
            pauseOnMouseEnter: true,
          }}
          navigation={true}
          grabCursor={promotions.length > 1}
          breakpoints={{
            // when window width is <= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            // when window width is <= 480px
            425: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // when window width is <= 640px
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {promotions.map((p, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`${p.image?.url}`} alt={p.image?.name} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default PromotionCarousel;
