import { IProduct } from '../../../lib/fetch/products';
import { currency } from '../../../lib/utils';

interface IPromotionPrice {
  product: IProduct;
}

const PromotionPrice: React.FC<IPromotionPrice> = ({ product }) => {
  return (
    <>
      <div className="flex items-center">
        <span className="mr-2 inline-flex items-center rounded-md border border-gray-200 bg-gray-100 px-3 py-0.5 text-xl font-semibold text-red-600">
          {currency(product.attributes.priceDiscounted || 0)}
        </span>
        <span className="text-3xl text-gray-700 line-through">{currency(product.attributes.price_net || 0)}</span>
      </div>

      {product.attributes.promotions.data[0]?.description && (
        <div className="mt-4 rounded-md bg-gray-100 px-3 py-2">
          <span className="text-sm text-red-500">{product.attributes.promotions.data[0].description}</span>
        </div>
      )}
    </>
  );
};

export default PromotionPrice;
