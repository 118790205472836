/**
 * NB: questa pagina (e le altre relative a sofinn) sono state copiate dal portale sofinn.
 * Pertanto eventuali incongruenze e/o grafiche e strutture poco piacevoli non sono di responsabilità del sottoscritto
 */

import { useTranslation } from 'react-i18next';
import SofinnInput from '../../../components/Sofinn/SofinnInput';
import SofinnButton from '../../../components/Sofinn/SofinnButton';
import { useFormContext } from 'react-hook-form';
import { ILoginForm } from '../../Login';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import SofinnErrorBox from '../../../components/Sofinn/SofinnErrorBox';

interface Props {
  onSubmit: (formData: ILoginForm) => void;
  isError: boolean;
}
/**
 *
 * @param props
 * @returns react element
 */
const FormLoginSofin: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const serverError = useMemo(() => searchParams.get('error'), [searchParams]);

  const { onSubmit, isError } = props;
  const { t } = useTranslation('LOGIN_SOFINN');
  const { register, handleSubmit } = useFormContext<ILoginForm>();

  return (
    <div className="p-[15px] pt-[20px]">
      <strong className="flex w-full text-center flex-col justify-center align-middle mb-2">
        <span>{t('ALLOWED_BROWSERS')}</span>
        <span className="italic underline text-[#ff0000]">{t('ALLOWED_BROWSERS_NAMES')}</span>
      </strong>
      <span className="text-[13px]">{t('VATCODE')}</span>
      <h4 className="text-[#710d28] text-[18px] mb-[10px]">{t('WELCOME_CATI')}</h4>
      <form className="flex w-full flex-col" onSubmit={handleSubmit(onSubmit)}>
        <table className="flex w-full">
          <tbody className="flex w-full flex-col">
            {(isError || serverError) && (
              <SofinnErrorBox errorMessage={serverError || "I dati immessi per l'utente non sono corretti."} />
            )}
            <tr className="flex w-full py-[2px]">
              <td className="p-[3px] min-w-[100px]  text-[12px] text-center font-bold">{t('LOGIN')}:</td>
              <td className="flex w-full">
                <SofinnInput required type="text" autoComplete="new-text" id="identifier" {...register('identifier')} />
              </td>
            </tr>
            <tr className="flex w-full py-[2px]">
              <td className="p-[3px] min-w-[100px] text-[12px] text-center font-bold">{t('PASSWORD')}:</td>
              <td className="flex w-full">
                <SofinnInput
                  required
                  autoComplete="new-password"
                  type="password"
                  id="password"
                  {...register('password')}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <SofinnButton type="submit" text="Accedi" />
      </form>
    </div>
  );
};

export default FormLoginSofin;
