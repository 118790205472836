import axios from 'axios';
import { ILoginResponse } from '../../auth';

const instanceAxios = axios.create();

const { REACT_APP_LOGIN_SOFINN_URL } = process.env;

interface ILoginRequest {
  identifier: string;
  password: string;
}

const sofinnLogin = async (credentials: ILoginRequest) => {
  return await instanceAxios.post<ILoginResponse>(REACT_APP_LOGIN_SOFINN_URL!, credentials, {});
};

export default sofinnLogin;
