import { FC } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { classNames, quantityInputProps as _quantityInputProps } from '../../lib/utils';
import { IProduct } from '../../lib/fetch/products';
import { useAuth } from '../../providers/AuthProvider';

interface IAddCartField {
  onAdd: (quantity: number) => void;
  product: IProduct;
  setQuantity?: (quantity: number) => void;
}

const AddCartField: FC<IAddCartField> = (props) => {
  const [{ user }] = useAuth();

  const { onAdd = () => {}, product, setQuantity } = props;
  const quantityInputProps = _quantityInputProps(product);
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      quantity: quantityInputProps.defaultValue,
    },
  });
  const quantity = watch('quantity');

  if (setQuantity) {
    setQuantity(quantity);
  }
  const disabled =
    quantityInputProps.disabled ||
    !product.attributes.price_net ||
    !product.attributes.active ||
    (product.attributes.quantity.quantityDefaultWarehouse === 0 &&
      product.attributes.quantity.quantityOtherWarehouses === 0 &&
      !user!.canBuyOutOfStock);
  return (
    <form
      className="flex items-center justify-end"
      onSubmit={handleSubmit(({ quantity }) => {
        !disabled && onAdd(+quantity || 0);
      })}
    >
      <input
        type="number"
        className="mr-2 block w-full w-20 rounded-md border-transparent bg-gray-100 py-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm min-w-16	"
        required
        {...quantityInputProps}
        min={1}
        //max={product.attributes.quantity.quantityDefaultWarehouse + product.attributes.quantity.quantityOtherWarehouses}
        {...register('quantity', {
          required: true,
          /*
          min: quantityInputProps.min,
          max:
            product.attributes.quantity.quantityDefaultWarehouse + product.attributes.quantity.quantityOtherWarehouses,
            */
          disabled,
          valueAsNumber: true,
        })}
      />
      <button
        type="submit"
        disabled={disabled}
        className={classNames(
          'flex items-center justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
          !disabled && 'hover:bg-red-700',
        )}
        style={disabled ? { cursor: 'not-allowed' } : {}}
      >
        <ShoppingCartIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </form>
  );
};

export default AddCartField;
