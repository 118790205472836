import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { getThumbnail } from './index';
import { CHECKOUT_PAYMENT_PATHNAME, PRODUCT_DETAIL_PATHNAME } from '../../App';
import { HIDDEN_MANUFACTURERS_NAMES, IS_ONLY_WIRE_TRANSFER_ENABLED } from '../../lib/constants';
import { OrderStatus } from '../../lib/enums';
import { IOrder } from '../../lib/fetch/orders';
import { classNames, currency, orderUtils } from '../../lib/utils';
import { SingleWordButtonPrimary } from '../Buttons';
import WireTransferInfo, { getPendingWireTransfer } from '../WireTransferInfo';

export { default as Pagination } from './Pagination';

interface IOrdersTable {
  orders: IOrder[];
}

const OrdersTable = (props: IOrdersTable) => {
  const navigate = useNavigate();
  const { orders = [] } = props;
  const { t } = useTranslation('MY_ORDERS');

  const NotPaidStatus = memo((props: { order: IOrder }) => {
    const { order } = props;
    return getPendingWireTransfer(order) ? (
      <span className="font-semibold text-yellow-600">{t('WAITING_FOR_WIRE_TRANSFER')}</span>
    ) : (
      <span className="font-semibold text-red-700">{t('NOT_PAID')}</span>
    );
  });

  const NotPaidPayNowButton = memo((props: { order: IOrder }) => {
    const { order } = props;
    const hasPendingWireTransfer = !!getPendingWireTransfer(order);
    const queryParams: {
      orderId: number;
      disableWireTransfer?: 0 | 1;
    } = {
      orderId: order.id,
    };
    if (hasPendingWireTransfer) {
      queryParams.disableWireTransfer = 1;
      if (IS_ONLY_WIRE_TRANSFER_ENABLED) return null;
    }

    return (
      <SingleWordButtonPrimary
        onClick={() => navigate(`${CHECKOUT_PAYMENT_PATHNAME}?${queryString.stringify(queryParams)}`)}
        text={t(hasPendingWireTransfer ? 'PAY_DIFFERENT_METHOD' : 'PAY_NOW')}
      />
    );
  });

  return (
    <>
      {orders.map((order) => {
        const totalItemsCount = order.attributes.order_items.data.reduce(
          (acc, item) => acc + item.attributes.quantity,
          0,
        );
        const totalTaxes = order.attributes.order_items.data.reduce((acc, item) => {
          const { attributes } = item || {};
          const { quantity, taxes } = attributes || {};
          return acc + ((quantity || 1) * (taxes || 0) || 0);
        }, 0);

        const showTableFooter =
          order.attributes.status === OrderStatus.NotPaid || order.attributes.status === OrderStatus.Delivered;

        return (
          <div key={order.id} className="mb-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-500"
                        >
                          {t('PLACED_ON')}:<br />
                          <span className="font-semibold">
                            {moment(order.attributes.date).format('DD MMM YYYY HH:mm:ss')}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-500"
                        >
                          {t('SENT_TO')}:<br />
                          <span className="font-semibold">
                            {order.attributes.shipping_address
                              ? order.attributes.shipping_address.name
                              : order.attributes.ord_ship_address.data.attributes.name}
                          </span>
                          {order.attributes.tracking_code && (
                            <>
                              <br />
                              <span
                                className={classNames(
                                  'font-semibold',
                                  order.attributes.status === OrderStatus.Delivered
                                    ? 'text-green-500'
                                    : 'text-yellow-600',
                                )}
                              >
                                {`${t('TRACKING')} ${order.attributes.tracking_code}${
                                  order.attributes.express_courier ? ` (${order.attributes.express_courier})` : ''
                                }`}
                              </span>
                            </>
                          )}
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-500"
                        >
                          {t('PIECES_NUMBER')}:<br />
                          <span className="rounded-full bg-red-100 py-0.5 px-2.5 font-medium text-red-600">
                            {totalItemsCount}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light tracking-wider  text-gray-500"
                        >
                          <span className="uppercase">{t('ORDER_STATUS')}:</span>
                          <br />
                          {(() => {
                            switch (order.attributes.status) {
                              case OrderStatus.Delivered:
                                return <span className="font-semibold text-green-500">{t('DELIVERED')}</span>;
                              case OrderStatus.Error:
                                return <span className="font-semibold text-red-700">{t('ERROR')}</span>;
                              case OrderStatus.NotPaid:
                                return <NotPaidStatus order={order} />;
                              case OrderStatus.Canceled:
                                return <span className="font-semibold text-red-700">{t('CANCELED')}</span>;
                              case OrderStatus.Shipped:
                                return <span className="font-semibold text-yellow-600">{t('SHIPPED')}</span>;
                              default:
                                return <span className="font-semibold text-yellow-600">{t('UNDER_PROCESSING')}</span>;
                            }
                          })()}
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-right text-xs font-light  tracking-wider text-gray-500"
                        >
                          <span className="uppercase">
                            {t('ORDER')} # {order.attributes.supermax_order_number}
                          </span>
                          <br />
                          tot.{' '}
                          <span className="text-lg font-semibold text-red-500">{currency(order.attributes.total)}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {order.attributes.order_items.data.map((item) => (
                        <tr key={item.id} className="border-t">
                          <td colSpan={3} className="whitespace-nowrap px-4 py-4">
                            <div className="flex items-center">
                              <div className="mr-4 text-sm font-medium text-gray-700">
                                <span className="rounded-lg bg-gray-100 px-3 py-2">{item.attributes.quantity} x</span>
                              </div>
                              {getThumbnail(item.attributes.productThumbnail)}
                              <Link to={`${PRODUCT_DETAIL_PATHNAME}/${item.attributes.productId}`} className="ml-3">
                                <div className="text-sm font-medium text-gray-900">{item.attributes.productCode}</div>
                                <div className="text-sm font-normal text-gray-500">
                                  {item.attributes.productStandardDescription ||
                                    item.attributes.productName ||
                                    item.attributes.productFamilyName}
                                </div>
                                {item.attributes.productManufacturerLogo ? (
                                  <img className="h-auto w-20" src={item.attributes.productManufacturerLogo} alt="" />
                                ) : item.attributes.productManufacturerName &&
                                  !HIDDEN_MANUFACTURERS_NAMES.includes(
                                    `${item.attributes.productManufacturerName}`.toUpperCase(),
                                  ) ? (
                                  item.attributes.productManufacturerName
                                ) : (
                                  item.attributes.productManufacturerId || '-'
                                )}
                              </Link>
                            </div>
                          </td>

                          <td
                            colSpan={2}
                            className="whitespace-nowrap px-4 py-4 text-right text-lg font-semibold text-gray-700"
                          >
                            <span className="text-sm font-normal text-gray-400">
                              {item.attributes.quantity} x {currency(item.attributes.price)}
                            </span>
                            <span className="ml-4">{currency(item.attributes.quantity * item.attributes.price)}</span>
                          </td>
                        </tr>
                      ))}
                      {/*                       <tr className="border-t">
                        <td colSpan={3} className="whitespace-nowrap px-4 py-4">
                          <div className="flex items-center">
                            <div className="text-sm font-medium uppercase text-gray-900">
                              {orderUtils.getOrderTaxName(order) || t('PRODUCTS:TAXES')}
                            </div>
                          </div>
                        </td>
                        <td
                          colSpan={2}
                          className="whitespace-nowrap px-4 py-4 text-right text-lg font-semibold text-gray-700"
                        >
                          <span className="ml-4">{totalTaxes ? currency(totalTaxes) : '-'}</span>
                        </td>
                      </tr> */}
                      {/*                       <tr className="border-t">
                        <td colSpan={3} className="whitespace-nowrap px-4 py-4">
                          <div className="flex items-center">
                            <div className="text-sm font-medium uppercase text-gray-900">{t('PRODUCTS:SHIPPING')}</div>
                          </div>
                        </td>
                        <td
                          colSpan={2}
                          className="whitespace-nowrap px-4 py-4 text-right text-lg font-semibold text-gray-700"
                        >
                          <span className="ml-4">
                            {order.attributes.shipping ? currency(order.attributes.shipping) : '-'}
                          </span>
                        </td>
                      </tr> */}
                    </tbody>
                    {showTableFooter ? (
                      <tfoot>
                        {order.attributes.status === OrderStatus.NotPaid ? (
                          <tr>
                            <td colSpan={4} className="px-4 py-4">
                              <WireTransferInfo order={order} />
                            </td>
                            <td colSpan={1} className="px-4 py-4 text-right">
                              <div className="flex items-center justify-end">
                                <NotPaidPayNowButton order={order} />
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        {order.attributes.status === OrderStatus.Delivered ? (
                          <tr>
                            <td colSpan={5} className="px-4 py-4 text-right">
                              <div className="flex items-center justify-end">
                                <SingleWordButtonPrimary
                                  onClick={() => navigate('/profile/return-info')}
                                  text={t('RETURN_REQUEST_BTN')}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        {/* TODO: VIEW INVOICE */}
                        {/*<tr>*/}
                        {/*  <td colSpan={5} className="px-4 py-4 text-right">*/}
                        {/*    <div className="flex items-center justify-end">*/}
                        {/*      /!* <a href="#" target="_blank">*/}
                        {/*        <SingleWordButtonPrimary*/}
                        {/*          onClick={() => {}}*/}
                        {/*          text={t('VIEW_INVOICE_BTN')}*/}
                        {/*        />*/}
                        {/*      </a> *!/*/}
                        {/*      /!* <span*/}
                        {/*        className="mx-4 h-8 w-px bg-gray-200"*/}
                        {/*        aria-hidden="true"*/}
                        {/*      ></span> *!/*/}
                        {/*    </div>*/}
                        {/*  </td>*/}
                        {/*</tr>*/}
                      </tfoot>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrdersTable;
