import { IProduct } from '../../lib/fetch/products';
import { currency } from '../../lib/utils';

interface IPromotionField {
  product: IProduct;
}
const PromotionField: React.FC<IPromotionField> = ({ product }) => {
  return (
    <>
      <div className="flex items-center gap-2">
        <span className="inline-flex items-center rounded-md border border-gray-200 bg-gray-100 px-2.5 py-0.5 text-sm font-semibold text-red-600">
          {currency(product.attributes.priceDiscounted || 0)}
        </span>
        <span className="mr-2 text-sm font-semibold text-gray-700 line-through">
          {currency(product.attributes.price_net || 0)}
        </span>
      </div>
      {product.attributes.promotions.data[0]?.description && (
        <div className="mt-1 w-36 truncate text-xs font-bold text-red-600">
          <span>{product.attributes.promotions.data[0].description}</span>
        </div>
      )}
    </>
  );
};

export default PromotionField;
