import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../../lib/constants/regexp';

const resetTemporaryPasswordValidator: any = yup
  .object({
    password: yup
      .string()
      .required('Questo campo è obbligatorio')
      .min(8, 'La password deve contenere almeno 8 caratteri')
      .matches(
        PASSWORD_REGEXP,
        'Password non valida. La password deve contenere almeno una maiuscola, una minuscola e un numero',
      ),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Le password devono coincidere')
      .required('Questo campo è obbligatorio'),
  })
  .required();

export default resetTemporaryPasswordValidator;
