import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { classNames } from '../../lib/utils';

interface Props {
  text?: string;
}

const SofinnButton: React.FC<Props & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  text,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        'flex w-full bg-[#710d28] border-[#160208] border-[1px] text-[#fff] text-[12px] rounded-[3px] py-[5px] px-[10px] mt-[20px] text-center hover:bg-[#dc2626] justify-center',
        props.className,
      )}
    >
      {text}
    </button>
  );
};

export default SofinnButton;
