import { Disclosure } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDetailsTab } from '../../../lib/enums';
import { IProduct, ITecdocProduct, IPromotion } from '../../../lib/fetch/products';
import { ITab, TabBarSmall } from '../../TabBars';
import { CrossReferences, TechnicalSpecs, Promotions } from './Tabs';

interface IDetailsDisclousre {
  product: { id: number; tecdoc?: ITecdocProduct };
  promotions?: IPromotion[];
  onAddToCart?: (product: IProduct, quantity: number) => void;
  showTechnicalSpecs?: boolean;
  showCrossReferences?: boolean;
  showPromotions?: boolean;
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
}

const DetailsDisclousre: FC<IDetailsDisclousre> = (props) => {
  const {
    product,
    promotions,
    onAddToCart,
    onFetchCrossReferences = () => [],
    showTechnicalSpecs = true,
    showCrossReferences = true,
    showPromotions = true,
  } = props;
  const { t } = useTranslation('PRODUCTS');
  const [selectedTab, setSelectedTab] = useState(ProductDetailsTab.TECHNICAL_SPECS);
  const [crossReferences, setCrossReferences] = useState<IProduct[] | undefined>();
  const [loadingCrossReferences, setLoadingCrossReferences] = useState(false);

  const tabs: ITab[] = [
    {
      name: t(ProductDetailsTab.TECHNICAL_SPECS),
      current: selectedTab === ProductDetailsTab.TECHNICAL_SPECS,
      onClick: () => setSelectedTab(ProductDetailsTab.TECHNICAL_SPECS),
      hidden: !showTechnicalSpecs,
    },
    {
      name: t(ProductDetailsTab.CROSS_REFERENCES),
      current: selectedTab === ProductDetailsTab.CROSS_REFERENCES,
      onClick: () => setSelectedTab(ProductDetailsTab.CROSS_REFERENCES),
      hidden: !showCrossReferences,
    },
    {
      name: t(ProductDetailsTab.PROMOTIONS_TAB),
      current: selectedTab === ProductDetailsTab.PROMOTIONS_TAB,
      onClick: () => setSelectedTab(ProductDetailsTab.PROMOTIONS_TAB),
      hidden: !showPromotions,
    },
  ];

  useEffect(() => {
    if (!product.id || selectedTab !== ProductDetailsTab.CROSS_REFERENCES || !!crossReferences) return;
    (async () => {
      setLoadingCrossReferences(true);
      const crossReferences = await onFetchCrossReferences(product.id);
      setCrossReferences(crossReferences);
      setLoadingCrossReferences(false);
    })();
  }, [product.id, selectedTab]);

  return (
    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
      {/* TODO: handle product preview */}
      <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
        <div className="bg-gray-50 py-3 pl-2">
          <TabBarSmall tabs={tabs} />
        </div>
        {selectedTab === ProductDetailsTab.TECHNICAL_SPECS && (
          <>
            <div className="px-3 py-4">
              <TechnicalSpecs product={product} />
            </div>
          </>
        )}
        {selectedTab === ProductDetailsTab.CROSS_REFERENCES && (
          <>
            <div className="py-4 px-3">
              <CrossReferences
                crossReferences={crossReferences || []}
                onAddToCart={onAddToCart}
                loading={loadingCrossReferences}
              />
            </div>
          </>
        )}
        {selectedTab === ProductDetailsTab.PROMOTIONS_TAB && (
          <>
            <div className="py-4 px-3">
              <Promotions promotions={promotions || []} />
            </div>
          </>
        )}
      </div>
    </Disclosure.Panel>
  );
};

export default DetailsDisclousre;
