import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import FullScreenLoading from '../../components/FullScreenLoading';
import { TabBarBig, ITab } from '../../components/TabBars';
import MessageAlert from '../../components/Alert/MessageAlert';
import Table, { HorizontalFilter, Pagination } from '../../components/ProductsTable';
import { IProduct } from '../../lib/fetch/products';
import MagGlass from '../../lotties/mag-glass.json';
import ShoppingCart from '../../lotties/shopping-cart.json';
import { Sidebar } from '../../components/Sidebar';
import { IFrameBannerSearchBottom } from '../../components/IFrameBanner/SearchBottom';

interface ISearchResultsPresentational {
  adding: boolean;
  loading: boolean;
  onAddToCart: (product: IProduct, quantity: number) => void;
  onFetchCrossReferences: (productId: number) => Promise<IProduct[]>;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  products: IProduct[];
  search?: string;
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
  where: string | null | undefined;
}

const SearchResultsPresentational: FC<ISearchResultsPresentational> = (props) => {
  const {
    adding = false,
    loading = false,
    onAddToCart = () => {},
    onFetchCrossReferences,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    products = [],
    search,
    page = 1,
    pageCount = 1,
    pageSize = 0,
    total = 0,
    where,
  } = props;
  const { t } = useTranslation('PRODUCTS');

  const tabs: ITab[] = [
    {
      name: t('ALL'),
      count: total,
      current: true,
    },
    /*
    {
      name: t('WITHIN_TODAY'),
      count: 0,
      current: false,
    },
    {
      name: t('WITHIN_NEXT_DAY'),
      count: 0,
      current: false,
    },
    {
      name: t('WITHIN_TWO_DAYS'),
      count: 0,
      current: false,
    },
    */
  ];
  return (
    <div className="min-h-screen">
      <Header />

      <div className="mx-auto w-full flex-grow overflow-x-hidden lg:flex lg:px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          <Sidebar />
          <div className="bg-white lg:min-w-0 lg:flex-1">
            <div className="h-full py-6 px-4 lg:pr-0">
              {!search && (
                <div className="mx-auto max-w-xl text-center">
                  <MessageAlert text={t('ADD_SEARCH')} />
                </div>
              )}
              {search && !loading && !products?.length && (
                <div className="mx-auto max-w-xl text-center">
                  <MessageAlert text={where === 'code' ? t('NO__PARTIAL_CODE_RESULT') : t('NO_RESULT')} />
                </div>
              )}

              <FullScreenLoading
                loading={loading}
                lottie={MagGlass}
                title={t('SEARCH_IN_PROGRESS_TITLE')}
                subtitle={t('SEARCH_IN_PROGRESS_SUBTITLE')}
                height={200}
                width={400}
              />

              <FullScreenLoading
                loading={adding}
                lottie={ShoppingCart}
                title={t('SHOPPING_CART:UPDATING_TITLE')}
                subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
                height={200}
                width={400}
              />

              {products?.length ? (
                <div>
                  <TabBarBig tabs={tabs} />
                  <HorizontalFilter />
                  <Table
                    onAddToCart={onAddToCart}
                    products={products}
                    onFetchCrossReferences={onFetchCrossReferences}
                  />
                  <Pagination
                    currentItems={products}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    page={page}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    total={total}
                  />
                </div>
              ) : null}
              <IFrameBannerSearchBottom />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchResultsPresentational;
