import { useMutation } from 'react-query';
import SofinnLoginPresentational from './SofinnLoginPresentational';
import { FormProvider, useForm } from 'react-hook-form';
import sofinnLogin from '../../../lib/fetch/sofinn/auth/login';
import { useNavigate } from 'react-router-dom';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useEffect } from 'react';

export interface ILoginForm {
  identifier: string;
  password: string;
  remember: boolean;
}

const SofinnLogin = () => {
  const navigate = useNavigate();
  const [{ isSofinnAuthenticated }, dispatchAuthChange] = useAuth();
  const loginFormMethods = useForm<ILoginForm>();

  const { mutate, isError, isLoading } = useMutation('sofinnLogin', sofinnLogin, {
    onSuccess: ({ data }) => {
      const { user, jwt } = data;
      dispatchAuthChange({
        type: AuthActionType.SofinnLogin,
        sofinnToken: jwt,
        remember: true,
        sofinnUser: user,
      });
      navigate('/cati-b2b/redirect');
    },
  });

  const onLogin = (values: ILoginForm) => {
    mutate(values);
  };

  useEffect(() => {
    /**
     * Se l'utente ha già inserito delle credenziali corrette allora non gli facciamo più vedere la pagina di login
     */
    if (isSofinnAuthenticated) {
      navigate('/cati-b2b/question-reset-password');
    }
  }, [isSofinnAuthenticated]);

  return (
    <FormProvider {...loginFormMethods}>
      <SofinnLoginPresentational loading={isLoading} onLogin={onLogin} isError={isError} />
    </FormProvider>
  );
};

export default SofinnLogin;
