import { useQuery } from 'react-query';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import { NewsPost } from '../../lib/models/NewsPost';
import { fetchNews } from '../../lib/fetch/newsposts';
import { useEffect, useState } from 'react';
import { NewsItem } from '../../components/News/NewsItem';
import NewsDetail from '../../components/News/NewsDetail';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../components/Alert/MessageAlert';
import { Pagination } from '../../components/ProductsTable';
import i18n from '../../i18n';
import { Sidebar } from '../../components/Sidebar';
import fetchPromotions from '../../lib/fetch/promotions/list';
import { Promotion } from '../../lib/models/Promotion';
import PromotionCarousel from '../../components/News/PromotionCarousel/PromotionCarousel';
import { IFrameBannerProfileBottom } from '../../components/IFrameBanner/ProfileBottom';
import { AxiosResponse } from 'axios';

const { REACT_APP_DEFAULT_PAGE_SIZE } = process.env;

const NewsList = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NewsPost | null>(null);
  const { t } = useTranslation('COMMON');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_PAGE_SIZE! || 25);

  const handleClick = (item: NewsPost) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const {
    data: newsposts,
    isError,
    isLoading,
    refetch,
  } = useQuery<PaginatedResponse<NewsPost>>('newsposts', () => fetchNews(token, { page, sort: ['date:desc'] }), {
    onSuccess,
    onError,
  });

  const { data: promotions } = useQuery<AxiosResponse<Promotion[]>>('promotions', () => fetchPromotions(token), {
    onSuccess,
    onError,
  });

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang, page, pageSize]);

  return (
    <div className="min-h-screen">
      <Header />
      {isError && (
        <div className="h-full py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      <div className="mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}

          <div className="flex">
            <div className="w-max p-4">
              <Sidebar />
            </div>

            <div className="w-auto my-12">
              <div className="flex flex-col ml-4">
                {promotions && promotions.data.length > 0 && (
                  <div className="p-8 rounded-lg overflow-hidden">
                    <PromotionCarousel promotions={promotions.data} />
                  </div>
                )}

                {newsposts && (
                  <div className="p-8">
                    <div className="grid grid-cols-1 gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                      {newsposts.data.map((news) => (
                        <NewsItem key={news.id} news={news} onClick={() => handleClick(news)} />
                      ))}
                    </div>
                    <Pagination
                      currentItems={newsposts.data}
                      onPageChange={(page: number) => setPage(page)}
                      onPageSizeChange={(size: number) => setPageSize(size)}
                      page={newsposts.meta.pagination.page}
                      pageCount={newsposts.meta.pagination.pageCount}
                      pageSize={newsposts.meta.pagination.pageSize}
                      total={newsposts.meta.pagination.total}
                    />
                  </div>
                )}

                <div className="p-8">
                  <IFrameBannerProfileBottom />
                </div>
              </div>
            </div>
          </div>

          <NewsDetail news={selectedItem} open={open} onClose={() => setOpen(false)} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsList;
